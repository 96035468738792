<template>
	<ValidationProvider :name="$attrs.name" :rules="rules" v-slot="{ errors }">
		<v-textarea
			v-model="innerValue"
			:error-messages="errors"
			v-bind="$attrs"
			v-on="$listeners"
			counter
			auto-grow
			:rows="rows"
			:disabled="disabled"
			:hint="hint"
			ref="inputRef"
			:class="classes"
			:hide-details="hideDetails"
			:color="color.hexa"
			:loading="isLoading"
			:filled="filled"
			:rounded="rounded"
		>
		</v-textarea>
	</ValidationProvider>
</template>

<script>
import {ValidationProvider} from "vee-validate";

export default {
	components: {
		ValidationProvider
	},
	props: {
		rules: {
			type: [Object, String],
			default: ""
		},
		// must be included in props
		value: {
			type: null
		},
		rows: {
			type: [String],
			default: "1"
		},
		classes: {
			type: [String],
			default: ""
		},
		color: {
			type: [String, Object],
			default: "primary"
		},
		isLoading: {
			type: [Boolean],
			default: false
		},
		rounded: {
			type: [Boolean],
			default: false
		},
		disabled: {
			type: [Boolean],
			default: false
		},
		filled: {
			type: [Boolean],
			default: false
		},
		hideDetails: {
			type: [Boolean],
			default: false
		},
		hint: {
			type: [String],
			default: ""
		},
	},
	data: () => ({
		innerValue: ""
	}),
	watch: {
		// Handles internal model changes.
		innerValue(newVal) {
			this.$emit("input", newVal);
		},
		// Handles external model changes.
		value(newVal) {
			this.innerValue = newVal;
		}
	},
	created() {
		if (this.value) {
			this.innerValue = this.value;
		}
	},
};
</script>
