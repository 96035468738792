<template>
    <div class="fill-height pa-5 pa-sm-10" v-if="AppStore.started_app" id="comment-page">
<!--        <stepper :step="2" :steps="3" v-if="$vuetify.breakpoint.smAndUp && AppStore.data.configuration.display_stepper"></stepper>-->
        <v-container class="fill-height d-flex align-center flex-row">
            <v-row class="">
                <v-card elevation="0" class="mx-auto pa-3 pa-sm-10" rounded="xl" width="100%">

                    <v-col cols="12" class="text-h2" id="comment-title"
                           v-html="AppStore.data.configuration[AppStore.locale].comment_title">
                    </v-col>

<!--                    <v-col cols="12 text-center" v-if="$vuetify.breakpoint.smAndUp">-->
<!--                        <v-alert-->
<!--                                dense-->
<!--                                text-->
<!--                                outlined-->
<!--                                id="comment-help"-->
<!--                                class="mb-0"-->
<!--                                v-if="outlinedTextArea"-->
<!--                                v-html="AppStore.data.configuration[AppStore.locale].comment_help"-->
<!--                                :color="AppStore.data.general.secondary_color.hexa"-->
<!--                        >-->
<!--                        </v-alert>-->
<!--                    </v-col>-->

                    <v-col cols="12" class="">
<!--                        <input-->
<!--                                type="text"-->
<!--                                v-autowidth="{maxWidth: '960px', minWidth: '20px', comfortZone: 0}"-->
<!--                                class="openKeyboardBtn text-button mb-5"-->
<!--                                @click="$refs.comment.focus(); outlinedTextArea = true"-->
<!--                                :style="'border-color:'+AppStore.data.general.primary_color.hexa+';background-color:'+AppStore.data.general.primary_color.hexa"-->
<!--                                :placeholder="AppStore.data.configuration[AppStore.locale].comment_keyboard_btn_text"-->
<!--                                v-if="$vuetify.breakpoint.smAndUp && !outlinedTextArea"-->
<!--                        />-->

                        <v-textarea
                                v-model="AppStore.rating.comment"
                                :placeholder="_.includes([0,1,2], AppStore.rating.rate) ? AppStore.data.configuration[AppStore.locale].comment_negative_placeholder : AppStore.data.configuration[AppStore.locale].comment_positive_placeholder"
                                persistent-placeholder
                                outlined
                                @focus="outlinedTextArea = true; commentFieldFocus = true"
                                ref="comment"
                                id="comment-textarea"
                                rows="6"
                                hide-details
                                class="mb-3"
                                name="comment">

                        </v-textarea>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <v-btn
                            v-if="AppStore.rating.comment.trim().length < AppStore.data.configuration.comment_chars_required_number"
                            @click="submit" rounded
                            outlined elevation="0"
                            :large="AppStore.data.general.button_size === 'large'"
                            :x-large="AppStore.data.general.button_size === 'x-large'"
                            :x-small="AppStore.data.general.button_size === 'x-small'"
                            :small="AppStore.data.general.button_size === 'small'"
                            :color="AppStore.data.general.primary_color.hexa" class="revert-btn text-button"
                            :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
                            v-html="AppStore.data.configuration[AppStore.locale].comment_retry_btn_text">
                        </v-btn>
                        <v-btn
                            v-if="AppStore.rating.comment.trim().length >= AppStore.data.configuration.comment_chars_required_number"
                            @click="submit" rounded elevation="0" dark
                            :large="AppStore.data.general.button_size === 'large'"
                            :x-large="AppStore.data.general.button_size === 'x-large'"
                            :x-small="AppStore.data.general.button_size === 'x-small'"
                            :small="AppStore.data.general.button_size === 'small'"
                            @keypress.enter="navigate"
                            class="text-button"
                            :color="AppStore.data.general.primary_color.hexa"
                            :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
                            v-html="AppStore.data.configuration[AppStore.locale].comment_validate_btn_text">
                        </v-btn>
                    </v-col>
                </v-card>
            </v-row>
        </v-container>
    </div>

</template>

<script>
import VTextAreaWithValidation from "../../../components/communs/inputs/VTextAreaWithValidation.vue";
import AppStore from "../../../stores/AppStore";
import _ from 'lodash';
import VueInputAutowidth from 'vue-input-autowidth'
import Vue from "vue";
import {EventBus} from "../../../services/Request";
import Helpers from "../../../services/Helpers";
// import Stepper from "../../../components/communs/Stepper.vue";

Vue.use(VueInputAutowidth)
export default {
    name: 'comment-view',

    components: {
        VTextAreaWithValidation
    },

    props: {},

    data: () => ({
        AppStore,
        outlinedTextArea: false,
        questions: [],
        appreciation_questions_to_display: [],
        commentFieldFocus: false,
    }),

    created() {

    },

    async mounted() {

    },

    computed: {
        Helpers() {
            return Helpers
        },
        _() {
            return _;
        }
    },

    watch: {},

    methods: {
        submit() {
            this.commentFieldFocus = false
            if(AppStore.data.configuration.skip_appreciation_page){
	            EventBus.$emit('sendRating', 'noReset')

	            this.$router.push({name: 'mb-2-timeline'})
            } else {
                this.$router.push({name: 'mb-2-appreciation'})
            }
        },
    }
};
</script>
<style scoped lang="scss">
.openKeyboardBtn {
  margin: 0 auto;
  display: block;
  min-width: 64px;
  height: 44px;
  padding: 0 16px;
  background-color: rgb(252, 119, 83);
  border-color: rgb(252, 119, 83);
  font-size: 1.2rem;
  color: white !important;
  border-radius: 28px;
  caret-color: transparent;
  font-weight: 700;
  letter-spacing: 0.0892857143em;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: uppercase;
  transition-duration: 0.28s;
  transition-property: box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.openKeyboardBtn::placeholder {
  color: white !important;
}
</style>
